import React from "react";

import { Link } from "gatsby";

import Image from "../../image";

import {
    BlogWrapper,
    Thumb,
    Category,
    Content,
    ContentTop,
    PostDate,
    PostTitle,
    Excerpt,
} from "./blog.stc";

const Blog = ({ title, date, id, author, path, image, excerpt }) => {
    return (
        <BlogWrapper>
            {image && (
                <Thumb>
                    <Link to={path}>
                        <Image fluid={image} alt={title} />
                    </Link>
                    <Category>
                        <Link to={path}>Creative</Link>
                    </Category>
                </Thumb>
            )}
            <Content>
                <ContentTop>
                    <PostDate>{date}</PostDate>
                    <PostTitle>
                        <Link to={path}>{title}</Link>
                    </PostTitle>
                    <Excerpt>{excerpt}</Excerpt>
                </ContentTop>
            </Content>
        </BlogWrapper>
    );
};

export default Blog;
